// Screen sizes
$screen-sm: 576px;
$screen-md: 768px;
$screen-lg: 992px;
$screen-xl: 1200px;
$screen-xxl: 1300px;
$screen-full: 1400px;

// Colors
$textColor: #42474C;
$textLight1Color: #6b7177;

$primaryDark2Color: #002e25;
$primaryDark1Color: #004C3F;
$primaryColor: #008060;
$primaryLight1Color: #C1F0D0;
$primaryLight2Color: #EFFCFA;

$secondaryColor: #906509;
$secondaryLight1Color: #FBF7ED;
$secondaryLight2Color: #FFF8F6;
$secondaryLight3Color: #E2E6DC;
$secondaryLight4Color: #EDE8DB;

$warningColor: #C43256;
$warningLight1Color: #c432571f;